





































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class OrderDetails extends Vue {
  loading: boolean = false;
  id!: any;
  orderData: any = null;
  pagerHeader: any = {
    title: "订单详细",
    desc: "订单详细",
    breadcrumb: ["订单管理", "订单详细"]
  };
  async readerOrderInfo() {
    this.loading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/ChargingOrder/QueryInfo",
        {
          id: this.id
        }
      );
      this.orderData = res.data.result;
    } catch (error) {}
    this.loading = false;
  }
  created() {
    this.id = this.$route.query.id;
    this.readerOrderInfo();
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.id = this.$route.query.id;
      this.readerOrderInfo();
    }
  }
}
